<template>
  <HeaderComponent />
  <router-view/>
  <FooterComponent />
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue';
import FooterComponent from './components/FooterComponent.vue';
import { homepage } from './app/routes';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent
  },
  methods: {
    async homepage() {
      // get sscid from url
      const urlParams = new URLSearchParams(window.location.search);
      const sscid = urlParams.get('sscid');
      if (sscid) {
        localStorage.setItem('sscid', sscid);
      }
      // post homepage visit
      const source = urlParams.get('source');
      const response = await homepage(source);
      if (response) {
        // localStorage.setItem('new', false);
      }
      
      // Clear URL parameters
      history.replaceState(null, null, window.location.pathname);
    }
  },
  mounted() {
    this.homepage();
  }
};
</script>


<style lang="scss">
html, body {
  overflow-x: hidden;
  padding: 0;
  margin: 0;
}

#app {
  font-family: "Questrial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;

  button, input {
    font-family: inherit;
  }
}
</style>
