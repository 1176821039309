import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/home',
    name: 'homepage',
    component: HomeView
  },
  {
    path: '/demo',
    name: 'demo',
    component: () => import(/* webpackChunkName: "demo" */ '../views/DemoView.vue')
  },
  // {
  //   path: '/features',
  //   name: 'features',
  //   component: () => import(/* webpackChunkName: "features" */ '../views/FeaturesView.vue')
  // },
  // {
  //   path: '/pricing',
  //   name: 'pricing',
  //   component: () => import(/* webpackChunkName: "pricing" */ '../views/PricingView.vue')
  // },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue')
  },
  {
    path: '/contact-confirmation',
    name: 'contact-confirmation',
    component: () => import(/* webpackChunkName: "contact-confirmation" */ '../views/ContactConfirmationView.vue')
  },
  {
    path: '/book-demo',
    name: 'book-demo',
    component: () => import(/* webpackChunkName: "book-demo" */ '../views/BookDemoView.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/PrivacyPolicyView.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/TermsView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

export default router
